<template>
    <div ref="columnRef" class="o365-calendar-grid-column position-absolute" data-dragscroll @scroll.passive="handleScroll"
        :class="{
            'o365-calendar-active-drop-target': isActiveDropTarget
        }"
        :style="{
            'left': scrollItem.pos + 'px',
            'width': cardWidth + 'px',
            'min-width': cardWidth + 'px',
            'max-width': cardWidth + 'px'
        }" @dragover="handleDragOver" @dragleave="handleDragLeave" @drop="handleDrop">
        <slot v-if="scrollItem.item.isLoading" name="placeholder">
            <div class="d-flex justify-content-center mt-2">
                <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </slot>

        <div v-else-if="calendarGridControl.props.disableVerticalVirtualScroll" class="w-100">
            <template v-for="(item, index) in scrollItem.item.items">
                <div class="o365-calendar-drag-item" :data-key="item.key" @click="() => calendarGridControl.dataObject.setCurrentIndex(item.index)"
                    :draggable="calendarGridControl.draggable" @dragstart="(e) => handleDragStart(e, index)">
                    <slot name="card" :row="item">
                        <div class="o365-calendar-card p-2 m-1 bg-white border" :class="{'border-primary': item.current }">
                            <slot name="cardContent" :row="item">
                                <b class="text-danger">no 'cardContent' or 'card' slot provided</b>
                            </slot>
                        </div>
                    </slot>
                </div>
            </template>
        </div>
        
        <div v-else class="d-flex w-100 position-relative"
            :style="{
                'min-height': totalHeight + 'px',
                'width': cardWidth + 'px',
            }">
            <template v-for="scrollRow in scrollData">
                <div class="o365-calendar-drag-item position-absolute" :data-key="scrollRow.key" @click="() => calendarGridControl.dataObject.setCurrentIndex(scrollRow.item.index)"
                    :style="{
                        'top': scrollRow.pos + 'px',
                        'height': cardHeight + 'px',
                        'min-height': cardHeight + 'px',
                        'max-height': cardHeight + 'px',
                        'max-width': `calc(${cardWidth}px - 15px)`,
                        'min-width': `calc(${cardWidth}px - 15px)`,
                    }" :draggable="calendarGridControl.draggable" @dragstart="(e) => handleDragStart(e, scrollRow.index)">
                    <slot name="card" :row="scrollRow.item">
                        <div class="o365-calendar-card p-2 m-1 bg-white border" :class="{'border-primary': scrollRow.item.current }"
                            :style="{
                                'height': cardHeight-cardHeightPadding + 'px',
                                'min-height': cardHeight-cardHeightPadding + 'px',
                                'max-height': cardHeight-cardHeightPadding + 'px',
                            }">
                            <slot name="cardContent" :row="scrollRow.item">
                                <b class="text-danger">no 'cardContent' or 'card' slot provided</b>
                            </slot>
                        </div>
                    </slot>
                </div>
            </template>
        </div>
     </div>
</template>

<script setup lang="ts">
import useVirtualScroll from 'o365.vue.composables.VirtualScroll.ts';
import logger from 'o365.modules.Logger.ts';

import { ref, computed } from 'vue';

const props = withDefaults(defineProps<{
    scrollItem: any,
    calendarGridControl: any,
    cardWidth: number
    cardHeight: number,
    cardHeightPadding: number
}>(), {});

const columnRef = ref(null);
const dataArray = computed(() => props.scrollItem.item.items);
const totalHeight = computed(() => props.scrollItem.item.items.length * props.cardHeight);

const isActiveDropTarget = ref(false);
let scrollData: any = null;
let handleScroll: any = () => {};
let updateVirtualScrollData: any = () => {};
let currentPosition: any = null;
if (!props.calendarGridControl.props.disableVerticalVirtualScroll) {
    const vs = useVirtualScroll({
        dataRef: dataArray,
        itemSize: props.cardHeight,
        elementRef: columnRef,
        watchTarget: () => dataArray.value.length,
        defaultRowHeight: props.cardHeight,
        // getRowHeight: row => row.details > 0 ? 300 : 300
    });
    scrollData = vs.scrollData;
    handleScroll = vs.handleScroll;
    updateVirtualScrollData = vs.updateData;
    currentPosition = vs.currentPosition;
}

let dragImage: HTMLElement | null = null;
function getDragImage(pElement: HTMLElement) {
    if (dragImage) { dragImage.remove(); }
    dragImage = pElement.cloneNode(true) as HTMLElement;
    dragImage.style.zIndex = '2000';
    dragImage.style.position = 'absolute';
    dragImage.style.left = '-9999px';
    dragImage.style.top = '-9999px';
    document.body.append(dragImage);
    return dragImage;
}

function cleanDragImage() {
    if (dragImage) {
        dragImage.remove();
        dragImage = null;
    }
}

function handleDragStart(pEvent: DragEvent, pLocalIndex: number) {
    pEvent.dataTransfer.setData('o365-nt/calendar-item', JSON.stringify({ columnIndex: props.scrollItem.index, rowIndex: pLocalIndex  }));
    pEvent.dataTransfer.effectAllowed = 'move';
    const dragImage = getDragImage(pEvent.target as HTMLElement);
    pEvent.dataTransfer.setDragImage(dragImage, pEvent.offsetX, pEvent.offsetY);
}

function handleDragOver(pEvent: DragEvent) {
    const isCalendarItem = pEvent.dataTransfer?.types.includes('o365-nt/calendar-item');
    if (!isCalendarItem) { return; }
    pEvent.dataTransfer.dropEffect = 'move';
    isActiveDropTarget.value = true;
    pEvent.preventDefault();
}

function handleDragLeave(pEvent: DragEvent) {
    if (columnRef.value.contains(pEvent.relatedTarget ) || columnRef.value == pEvent.relatedTarget ) { return; }
    if (isActiveDropTarget.value) {
        isActiveDropTarget.value = false;
    }
}

function handleDrop(pEvent: DragEvent) {
    isActiveDropTarget.value = false;
    try {
        const jsonString = pEvent.dataTransfer.getData('o365-nt/calendar-item');
        const json = JSON.parse(jsonString);
        if (json.columnIndex === props.scrollItem.index) { return; }
        const fromColumn = props.calendarGridControl.columns[json.columnIndex];
        const row = fromColumn.items.splice(json.rowIndex, 1)[0];
        props.scrollItem.item.items.unshift(row);
        row[props.calendarGridControl.props.bindingField] = props.scrollItem.item.startDate;
        row.save();
        props.calendarGridControl.dataObject.setCurrentIndex(row.index);
    } catch (ex) {
        logger.log(ex);
    } finally {
        cleanDragImage();
    }
}

</script>